@import "../variables.scss";

.loginPage {
  margin: 10px;

  .containerForLinks {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
  }

  .formSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      margin-bottom: 10px;
      text-align: right;
    }
  }

  .errorsBox {
    background-color: $dangerBgColor;
    position: relative;
    margin-bottom: 30px;
    max-width: max-content;
    padding-right: 16px;

    &::before {
      content: "";
      position: absolute;
      left: 16px;
      top: -31px;
      border: 10px solid transparent;
      border-bottom: 22px solid $dangerBgColor;
    }
  }

  .errorBorder {
    border: 1px solid $dangerColor;
  }

  .errorLabel {
    color: $dangerColor;
  }
}
