@import "../variables.scss";

.input {
  border-radius: 10px !important;
  min-width: 100px;
  background-color: $formFieldBgColor;
  border: none;
  outline: none;
  padding: 15px 18px;
  margin-bottom: 20px;
  color: $textColor;
  line-height: 17px !important;
}

.input,
.textarea {
  font-family: $font-family;
}

.label {
  margin-bottom: 5px;
}

.textarea {
  resize: none;
  min-height: 140px;
}
