@import "../../variables.scss";
@import "../../mixins.scss";
@import "../../shared.module.scss";

.addNewDevice {
  @include listViewDeviceCardBackgroundColor;

  @include responsive-min($size-md) {
    padding: 14px 41px;
  }

  @include responsive-max($size-md) {
    padding: 11px 19px;
  }

  .addNewDeviceWrapper {
    display: flex;
    align-items: center;
    min-height: 35px;
  }
}

.addNewDeviceContent {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $actionColor;

  &:hover {
    svg {
      path {
        fill: lighten($actionColor, 10%);
      }
    }

    .addNewDeviceLink {
      color: lighten($actionColor, 10%);
    }
  }
}

.addNewDeviceImg {
  display: flex;
  width: 50px;

  svg {
    height: 15px;
  }

  @include responsive-max($size-md) {
    width: 15px;
  }
}

.addNewDeviceLink {
  margin-left: 21px;
}
