@import "../variables.scss";
@import "../mixins.scss";

.devicesGridView {
  display: grid;
  grid-gap: 23px;
  grid-template-columns: repeat(auto-fit, minmax(176px, 337px));
  grid-auto-rows: 280px;

  @include responsive-max($size-lg) {
    justify-content: center;
  }
}

.skeleton {
  height: 100%;
}
