@import "../variables.scss";
@import "../mixins.scss";

.userSettings {
  @include responsive-max($size-sm) {
    padding: 0;
  }
}

.settingsWrapper {
  position: relative;
  margin: 0 auto;
  background-color: $backgroundColor;
  max-width: 700px;
  padding: 40px;
  border-radius: 5px;
  line-height: 17px;

  @include responsive-max($size-sm) {
    padding: 25px $sidePaddingMobile 40px;
    border-radius: 0;
  }
}

.containerForLink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.btnDanger {
  min-width: 160px;

  @include responsive-max($size-sm) {
    width: 210px;
  }
}

.userSettingsError {
  margin-bottom: 20px;
}

.hidden {
  display: none !important;
}

.btnGroup {
  margin-bottom: 50px;
}

.backLinkZindex {
  z-index: 11;
}

.userSettingsForm {
  margin-top: 40px;
}
