@import "../variables.scss";
@import "../mixins.scss";

.dialogOverlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 10px;
}

.dialogWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  width: 100%;
  background-color: $backgroundColor;
  padding: 32px 40px;
  border-radius: 10px;
}

.dialogContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 28px;
}

.dialogBtnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include responsive-min($size-sm) {
    .dialogBtn {
      margin: 0;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  @include responsive-max($size-sm) {
    & {
      flex-direction: column;
    }

    .dialogBtn {
      width: 100%;
      max-width: 100%;

      &:first-child {
        order: 1;
        margin-top: 10px;
      }
    }
  }
}
