@import "../variables.scss";

.dropdown {
  position: relative;
	min-width: 100px;
  color: $linkColor;
}

.popoverWrapper {
  position: absolute;
  top: 52px;
  right: 0;
  min-width: 150px;
  min-height: 128px;
  border-radius: 5px;
  border: 1px solid $popOverColor;
  background-color: $backgroundColor;
  z-index: 10;

  ul {
    padding: 0;
    margin: 0;
  }
}
