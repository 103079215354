@import "../variables.scss";

.label {
  color: $textColor;
}

.title {
  margin-bottom: 5px;
}

.value {
  padding-top: 12px;
  padding-bottom: 18px;
  font-weight: bold;
  line-height: 17px !important;
  margin-bottom: 20px;
}
