@import "../variables.scss";

.viewSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 37px;
}

.text {
  margin: 0;
  color: $textColor;
}

.viewTypeGroup {
  display: flex;
  align-items: center;
  color: $linkColor;

  .listViewIcon,
  .gridViewIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .gridViewIcon {
    margin-right: 15px;
  }
}

.inactive {
  opacity: 0.5;
}
