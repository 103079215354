@import "../../variables.scss";

.addNewDevice {
  display: flex;
  flex-direction: column;
  min-height: 280px;
  justify-content: space-between;
  padding: 20px;
  background-color: $backgroundColor;
  cursor: pointer;
  border-radius: 7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  color: $actionColor;
}

.addNewDeviceTitle {
  color: $linkColor;
  line-height: 1.6em;
}

.addNewDeviceImg {
  margin: 0px auto;
}

.addNewDeviceLink {
  align-self: center;
}
