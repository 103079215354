@import "../variables.scss";

.languageDropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: $headerTextColor;
}

.popoverWrapper {
  top: 36px !important;
}

.arrowOpen {
  transform: rotate(180deg);
}

.arrow {
  margin-left: 12px;
}

.optionItem {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  cursor: pointer;

  .optionItemName {
    margin: 0;
    color: $linkColor;
  }
}

.icon {
  margin-right: 11px;
  width: 24px;
  height: 24px;
}
