@import "../variables.scss";

.checkboxWrapper {
  white-space: nowrap;
  margin-bottom: 20px;
}

.input {
  display: none;

  &+label {
    display: inline-flex;
    align-items: center;
    white-space: normal;

    &::before {
      content: "";
      display: inline-block;
      width: 21px;
      min-width: 21px;
      height: 21px;
      border: 1px solid transparent;
      border-radius: 6px;
      background-color: $formFieldBgColor;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      margin-right: 7px;
    }
  }

  &:checked+label::before {
    border-color: $actionColor;
    background-color: $actionColor;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

}

.label {
  color: $linkColor;
}

.labelContent {
  align-self: flex-start;
}
