@import "./variables.scss";
@import "./mixins.scss";
@import "./typography.scss";

.title {
  text-align: center;
  margin-block-end: 30px;
  margin-block-start: 10px;
}

.subtitle {
  text-align: center;
  margin-block-end: 30px;
  margin-block-start: 10px;
}

.content {
  padding-left: $sidePaddingDesktop;
  padding-right: $sidePaddingDesktop;
  position: relative;

  @include responsive-max($size-sm) {
    padding: $topPaddingMobile $sidePaddingMobile 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 580px;
  margin: 0 auto;
}

.btnGroup {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0 0;
}

@mixin listViewDeviceCardBackgroundColor() {
  @include responsive-min($size-md) {
    &:nth-child(2n + 1) {
      background-color: $backgroundColor;
    }

    &:nth-child(2n) {
      background-color: $backgroundColorAltRows;
    }
  }

  @include responsive-max($size-md) {
    &:nth-child(2n + 1) {
      background-color: $backgroundColorAltRows;
    }

    &:nth-child(2n) {
      background-color: $backgroundColor;
    }
  }
}

.hidden {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.headerContainer {
  max-width: 800px;
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 25px;

  @include responsive-max($size-md) {
    flex-direction: column;
  }

  h1 {
    flex-grow: 1;
    text-align: center;
    margin: 0;

    @include responsive-max($size-md) {
      align-self: center;
    }
  }

  @include responsive-max($size-sm) {
    padding-top: 0;
  }
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: $linkColor;

  &:hover {
    color: lighten($linkColor, 10%);
  }

  &:active {
    color: lighten($linkColor, 5%);
  }
}

.backLink {
  @include responsive-min($size-md) {
    position: absolute;
  }

  @include responsive-max($size-md) {
    align-self: self-start;
  }
}

.formSeparator {
  border-bottom: 1px solid $separatorColor;
}

.pageTitle {
  @include pageTitle();
}

.mainText {
  @include mainText();
}

.formText {
  @include formText();
}

.secondaryText {
  @include secondaryText();
}
