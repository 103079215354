@import "./variables.scss";

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

html {
  overflow: auto;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundColor;
  color: $textColor;
}
