@import "branding.scss";

$font-family: "Roboto", sans-serif;

$sidePaddingDesktop: 50px;
$sidePaddingMobile: 16px;

$topPaddingMobile: 7px;

$headerTopPadding: 26px;
$headerBottomMargin: 38px;
$headerLogoHeightDesktop: 50px;
$headerLogoHeightMobile: 35px;

$footerTopMargin: 38px;
$footerHeight: 60px;

$size-xs: 0;
$size-xsm: 380px;
$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;
$size-xxl: 1400px;
