@import "./variables.scss";
@import "./mixins.scss";

.wrapper {
  @include customAppStyles();
}

.container {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}

.privateBackground {
  background-color: $backgroundLoggedColor;
}
