@import "../variables.scss";
@import "../mixins.scss";

.deviceSettings {
  @include responsive-max($size-sm) {
    padding: 0;
  }
}

.form {
  position: relative;
  max-width: 700px;
  padding: 40px 50px;
  background-color: $backgroundColor;
  border-radius: 5px;
  line-height: 17px;

  @include responsive-max($size-sm) {
    padding: 25px $sidePaddingMobile 30px;
    border-radius: 0;
  }
}

.btnGroup {
  margin-bottom: 40px;
}

.deviceSettingsError {
  margin-bottom: 20px;
}
