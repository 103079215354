@import "../variables.scss";

.backButton {
  align-self: center;
  margin-top: 25px;
}

.error {
  margin-bottom: 20px;
}
