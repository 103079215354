@import "../variables.scss";

.alert {
  text-align: center;
}

.error {
  color: $dangerColor;
}

.success {
  color: $successColor;
}
