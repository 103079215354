@import "../../variables.scss";

.outer {
  display: flex;
  justify-content: space-between;
  margin: 14px auto 26px;
  border-radius: 10px;
  background-color: #222;
  overflow: hidden;
  height: 75px;
  width: 300px;
  align-items: flex-start;
}

.errorsBox {
  background-color: $dangerBgColor;
  position: relative;
  padding-right: 16px;
  margin: 0 auto 30px;
  width: 300px;

  &::before {
    content: "";
    position: absolute;
    left: 16px;
    top: -31px;
    border: 10px solid transparent;
    border-bottom: 24px solid $dangerBgColor;
  }
}
