@use 'sass:math';

// accent: #0980ab -> hsl(196, 90%, 35%)
//  - lighter (hsl 45%): #0aa3db
//  - darker (hsl 25%): #065b7a
// solid bg: #c2c3c3
// gradient bg: linear-gradient(222deg, rgba(255,255,255,1) 5%, rgba(192,192,193,1) 32%, rgba(35,35,64,1) 87%);

$backgroundColor: #ffffff;
$backgroundLoggedColor: #f1f4f9;
$textColor: #3c3c3c;
$linkColor: #0980ab;
$actionColor: #0aa3db;
$dangerBgColor: #ffb9b9;
$dangerColor: #e40d0d;
$successColor: #52c41a;
$disabledBgColor: #e0e0e0;
$lightTextColor: #ffffff;
$offlineBgColor: #0980ab;
$offlineFgColor: #ffffff;

$headerTextColor: #dddddd;
$popOverColor: $linkColor;
$separatorColor: darken($backgroundLoggedColor, 12%);
$formFieldBgColor: $backgroundLoggedColor;
$backgroundColorAltRows: lighten($backgroundLoggedColor, 1.5%);

@mixin customHeaderStyles() {
  background-color: black;
  padding-top: math.div($headerTopPadding, 2);
  padding-bottom: math.div($headerTopPadding, 2);
}

@mixin customAppStyles() {
}
  