@import "../variables.scss";
@import "../mixins.scss";

.footer {
  position: relative;
  flex-shrink: 1;
  height: $footerHeight;
  width: 100%;
  margin-top: $footerTopMargin;

  .footerLinks {
    position: absolute;
    bottom: 34px;
    left: 50px;
    color: $linkColor;

    @include responsive-max($size-md) {
      bottom: 38px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      width: 100%;
      padding: $topPaddingMobile $sidePaddingMobile 0;
    }
  }
}
