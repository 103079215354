@mixin pageTitle {
  font-size: 24px;
  font-weight: 500;
}

@mixin mainText {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.7;
}

@mixin formText {
  font-size: 16px;
  font-weight: normal;
}

@mixin secondaryText {
  font-weight: normal;
  font-size: 14px;
}
