@import "../variables.scss";
@import "../mixins.scss";

.devicesListView {
  .devicesListViewWrapper {
    min-height: 600px;

    @include responsive-min($size-md) {
      padding: 45px 25px;
      background-color: $backgroundColor;
      border-radius: 7px;
    }
  }

  @include responsive-max($size-md) {
    padding: 0;
  }
}

.devicesListHeaders {
  display: flex;
  margin-bottom: 25px;

  .modelHeader,
  .nameHeader {
    color: $linkColor;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modelHeader {
    margin-left: 112px;
    width: 250px;

    @include responsive-max($size-lg) {
      width: 200px;
    }
  }

  .nameHeader {
    margin-left: 10px;
    flex-grow: 1;
  }

  @include responsive-max($size-md) {
    display: none;
  }
}

.skeleton {
  min-height: 63px;
  line-height: normal !important;

  @include responsive-max($size-md) {
    min-height: 57px;
  }
}

.odd {
  @include responsive-min($size-md) {
    background-color: $backgroundColor !important;
    background-image: none !important;
  }

  @include responsive-max($size-md) {
    background-color: $backgroundColorAltRows !important;
  }
}

.even {
  @include responsive-min($size-md) {
    background-color: $backgroundColorAltRows !important;
  }

  @include responsive-max($size-md) {
    background-color: $backgroundColor !important;
    background-image: none !important;
  }
}
