@import "../variables.scss";
@import "../mixins.scss";

.avatar {
  margin-left: 15px;
  //height: $avatarSize;  // set in JS
  //width: $avatarSize;   // set in JS
  border-radius: 100%;
}

.userDropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $headerTextColor;
}

.userDropdownWrapper {
  display: flex;
  align-items: baseline;
  overflow: hidden;
}

.arrow,
.avatar,
.userName {
  cursor: pointer;
}

.arrowOpen {
  transform: rotate(180deg);
}

.arrow {
  margin-left: 10px;
  flex-shrink: 0;
}

.optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: $linkColor;

  &:first-child {
    padding-top: 15px;
  }

  &:last-child {
    padding-bottom: 15px;
  }

  .optionItemName {
    margin: 0;
    white-space: nowrap;
  }
}

hr {
  background-color: $popOverColor;
  color: $popOverColor;
  height: 1px;
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
 }

.icon {
  margin-left: 20px;
}

.userName {
  display: inline-block;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 250px;
  padding-left: 10px;
}

@include responsive-between($size-xs, $size-xsm) {
  .userName {
    display: none;
  }
}
