@import "../variables.scss";
@import "../mixins.scss";

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include customAppStyles();
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  display: flex;
}

.icon {
  width: 100%;
  height: 100%;
  min-width: 200px;
  max-width: 350px;
  flex-shrink: 1;
  margin: 0 65px;
}

.message {
  margin: 35px;

  @include responsive-min($size-md) {
    margin: 45px;
  }

  text-align: center;
}

.footer {
  justify-self: flex-end;
}
