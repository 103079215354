@import "../../variables.scss";

.error {
  color: $dangerColor;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 12px 0 12px;

  &::before {
    content: "";
    min-width: 20px;
    min-height: 20px;
    background-image: url("../../assets/icons/iconmonstr-warning-5.svg");
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 8px 0 16px;
  }
}
