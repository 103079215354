@import "../variables.scss";
@import "../mixins.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: $headerTopPadding;
  margin-bottom: $headerBottomMargin;

  @include customHeaderStyles();
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo {
//width: 204px;
  width: auto;
  height: $headerLogoHeightDesktop;

  @include responsive-max($size-sm) {
    height: $headerLogoHeightMobile;
  }
}

.homeLink {
  display: flex;
}
