@import '../variables.scss';

.offlinePage {
  background-color: $offlineBgColor;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  margin-bottom: 50px;

  img {
    max-width: 100%;
  }
}

.info {
  color: $offlineFgColor;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: 0 20px;

  >div {
    margin-bottom: 20px;
  }
}
