@import "../variables.scss";
@import "../mixins.scss";

.statusPage {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 580px;

  @include responsive-max($size-sm) {
    padding: $sidePaddingMobile;
  }
}

.text {
  text-align: center;
  color: $textColor;
  margin: 39px 0 33px;
}
