@import "../variables.scss";
@import "../mixins.scss";

.hidden {
  display: none !important;
}

.signupPage {
  color: $textColor;
}

.link {
  text-decoration: none;
  margin-bottom: 10px;
  text-align: center;
  min-width: 110px;
  display: inline-block;
}

.button {
  width: 338px;
  margin-bottom: 25px;

  @include responsive-max($size-md) {
    width: 90%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 580px;
  margin: auto;
}

.containerForLinks {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.termsLink {
  text-align: right;
}

.alert {
  margin-bottom: 20px;
}
