@import "./variables.scss";

@mixin responsive-min($query) {
  @media (min-width: + $query) {
    @content;
  }
}

@mixin responsive-max($query) {
  @media (max-width: + $query - 0.02) {
    @content;
  }
}

@mixin responsive-between($min, $max) {
  @media (min-width: + $min) and (max-width: + $max - 0.02) {
    @content;
  }
}
