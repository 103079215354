@import "../variables.scss";
@import "../mixins.scss";
@import "../typography.scss";

.infoPage {
  display: flex;
  flex-direction: column;
  margin: 0;

  h1 {
    text-align: center;
  }

  .headerContainer {
    max-width: 800px;
    width: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include responsive-max($size-md) {
      flex-direction: column;
    }

    h1 {
      flex-grow: 1;

      @include responsive-max($size-md) {
        align-self: center;
      }
    }

    @include responsive-max($size-sm) {
      padding-top: 0;
    }
  }

  .contentContainer {
    overflow: auto;
  }
}

.content {
  font-family: $font-family;
  font-weight: normal;
  max-width: 800px;
  margin: 0 auto;

  @include responsive-max($size-sm) {
    padding-top: 0;
  }

  h1,
  h2 {
    @include pageTitle();
    margin-top: 1em;
    margin-bottom: 0.8em;
  }

  h3 {
    font-size: 1.25em;
    margin-top: 1.5em;
  }

  h2 + h3 {
    margin-top: 1em;
  }

  strong {
    font-weight: 500;
  }

  p,
  li,
  td {
    @include mainText();
  }

  a {
    color: $linkColor;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 2em;
  }

  :global(.updated) {
    font-size: .9em;
    margin-top: 3em;
  }

  :global(.no-bullets) {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  :global(.extra-margin-bot) {
    margin-bottom: 4em;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 2em 0 4em;
  }
  tr {
    border-bottom: 1px solid $separatorColor;
  }
  tr:last-child {
    border-bottom: none;
  }
  td {
    padding: .3em .7em;
  }

  hr {
    margin: 2.5em 0;
    border-top: none;
    border-bottom: 1px solid $separatorColor;
  }
}
