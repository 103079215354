@import "../../variables.scss";

.errorsBox {
  background-color: $dangerBgColor;
  position: relative;
  margin-bottom: 30px;
  max-width: max-content;
  padding-right: 16px;

  &::before {
    content: "";
    position: absolute;
    left: 16px;
    top: -31px;
    border: 10px solid transparent;
    border-bottom: 22px solid $dangerBgColor;
  }
}

.errorBorder {
  border: 1px solid $dangerColor;
}

.errorLabel {
  color: $dangerColor;

  &::before {
    border: 1px solid $dangerColor !important;
  }
}
