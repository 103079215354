@import "../variables.scss";

.skeleton {
    height: 100%;
    min-height: 63px;
}

.devicesError {
    margin-top: 100px;
}
