@import "../variables.scss";
@import "../mixins.scss";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  margin: 0 auto;
  max-width: 250px;
  font-family: $font-family;

  .buttonContent {
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;

    .buttonTextWithIcon {
      margin-right: 0;
    }
  }
}

.disabled {
  background-color: $disabledBgColor;
}

.danger {
  background-color: $dangerColor;
  color: $lightTextColor;

  &:hover:enabled {
    background-color: lighten($dangerColor, 10%);
  }

  &:active:enabled {
    background-color: lighten($dangerColor, 5%);
  }
}

.light {
  background-color: $backgroundColor;
  color: $actionColor;
  border: 1px solid $actionColor;
  z-index: 11;

  .buttonText {
    margin: 0;

    @include responsive-max($size-sm) {
      max-width: 175px;
    }
  }

  &:hover:enabled {
    background-color: darken($backgroundColor, 2%);
  }

  &:active:enabled {
    background-color: lighten($backgroundColor, 5%);
  }
}

.primary {
  background-color: $actionColor;
  color: $lightTextColor;

  &:hover:enabled {
    background-color: lighten($actionColor, 10%);
  }

  &:active:enabled {
    background-color: lighten($actionColor, 5%);
  }
}

.buttonText {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  display: none;
}

.buttonTextSm {
  max-width: 120px;
  margin: 0 27px;
}

.buttonTextLg {
  max-width: 200px;
  margin: 0 50px;

  @include responsive-max($size-md) {
    max-width: 130px;
  }
}

.sm {
  min-height: 33px;
  padding: 0 30px;

  .loader {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }

  @include responsive-max($size-sm) {
    max-width: 180px;
    min-width: 140px;
  }

  @include responsive-min($size-sm) {
    min-width: 170px;
  }
}

.lg {
  min-width: 330px;
  min-height: 45px;
  padding: 0 60px;

  .loader {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
  }

  @include responsive-max($size-md) {
    width: 90%;
    min-width: 100px;
  }
}

.icon {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
}
